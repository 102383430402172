

@media (min-width: 320px) and (max-width: 480px) {
    .header_wrap {
        .col_3 {
            grid-template-columns: 1fr;
            height: 100%;
            position: relative;
            h1 {
                padding-top: 24%;
            }
        }
        .header_detail_wrap {
            display: grid;
            grid-template-columns: 1fr;
            width: 100%;
            p {
                margin: 0;
            }
        }
    }
}

/* IPads, Tablets */
@media (min-width: 481px) and (max-width: 768px) {

}

/* Small Screens, Laptops */
@media (min-width: 769px) and (max-width: 1024px) {

}
/* Desktops, Large Screens */
@media (min-width: 1025px) and (max-width: 1200px) {

}
/* Extra Large Screens, TV */
@media (min-width: 1201px) {
    .header_wrap {
        margin-bottom: 10%;
            h1 {
                width: 75%;
                margin-bottom: 20%;
                margin-top: 10%;
            }
        .header_detail_wrap {
            .header_detail {
                margin-bottom: 0;
            }
        }
    }
    
}