.footer_wrap {
    h3 {
        text-align: center;
    }
    .col_3 {
        height: 30px;
    }
    p {
        font-size: 12px;
        margin-bottom: 0;
        margin-top: 10px;
        font-weight: lighter;
        text-align: center;
        color: grey;
    }
}


@media (min-width: 768px) and (max-width: 1024px) {

}

@media (min-width: 320px) and (max-width: 480px) {
    .footer_wrap {
        height: 50px;
        .col_3 {
            grid-template-columns: 1fr;
             grid-row-gap: 10px;
            width: 80%;
            margin: auto;
            padding-bottom: 50px;
        }
    }
}